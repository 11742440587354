import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 获取所有运单列表
export const GetWaybillAll = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillAll/GetWaybillAll`, params).then((res) => {
            if (res.code == 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        });
    });
};

// 获取所有运单统计
export const GetWaybillAllTotal = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillAll/GetWaybillAllTotal`, params).then((res) => {
            if (res.code == 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        });
    });
};

// 查询运单状态列表
export const GetWaybillStatusList = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/WayBillAll/GetWaybillStatusList`).then((res) => {
            if (res.code == 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        });
    });
};

// 查询所有运单下载
export const DownloadWaybillAll = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillAll/DownloadWaybillAll`, params).then((res) => {
            if (res.code == 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        });
    });
};