<template>
  <div>
    <allWaybill2 :roleType="'platform'"/>
  </div>
</template>
  
<script>
import allWaybill2 from "@/components/allWaybill2Cmpt/index";
export default {
  data() {
    return {}
  },
  components: {
    allWaybill2
  },
};
</script>